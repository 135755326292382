import Error from '../../components/Error/Error';

const Page404 = () => {
  return (
    <div style={{ marginTop: 'auto' }}>
      <Error />
    </div>
  );
};

export default Page404;
